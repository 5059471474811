.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Header Component Styles */

.language-selector {
  margin-left: auto;
  min-width: 120px;
}

.select-language {
  background-color: transparent; /* Remove background color */
  color: white; /* White text color */
  border: none; /* Remove border */
  padding: 4px 8px; /* Padding inside the dropdown */
  font-size: 14px; /* Adjust font size if needed */
  display: flex; /* Align items */
  align-items: center; /* Center items vertically */
}

.select-language .MuiSelect-icon {
  color: white; /* White color for the dropdown arrow */
}

.MuiSelect-select {
  color: white; /* Ensure text inside the select field is white */
}

.MuiMenuItem-root {
  color: white; /* White text color for dropdown items */
  background-color: transparent; /* Transparent background */
}

.MuiPaper-root {
  background-color: #333; /* Dark background for dropdown menu */
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

/* Ensure the select has no underline */
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none; /* Remove border */
}

.MuiInputBase-root {
  border: none; /* Remove border */
}










@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
